@font-face {
    font-family: "Gulfstream Horizon";
    font-weight: 100;
    src: url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_W_Lt.eot"); /* IE9 Compat Modes */
    src: url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_W_Lt.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_W_Lt.woff2") format("woff2"), /* Moderner Browsers */ url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_W_Lt.woff") format("woff"), /* Modern Browsers */ url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_Lt.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_Lt.otf") format("opentype"); /* Most Operating Systems */
    /* url('https://assets.gulfstream.aero/fonts/horizon/light/GulfstreamHorizon_Lt.svg#svgFontName') format('svg'); Legacy iOS */
}

@font-face {
    font-family: "Gulfstream Horizon";
    font-weight: 400;
    src: url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_W_Rg.eot");
    src: url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_W_Rg.eot?#iefix") format("embedded-opentype"), url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_W_Rg.woff2") format("woff2"), url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_W_Rg.woff") format("woff"), url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_Rg.ttf") format("truetype"),
        url("https://assets.gulfstream.aero/fonts/horizon/regular/GulfstreamHorizon_Rg.otf") format("opentype");
}

@font-face {
    font-family: "Gulfstream Horizon";
    font-weight: 700;
    src: url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_W_Bd.eot");
    src: url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_W_Bd.eot?#iefix") format("embedded-opentype"), url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_W_Bd.woff2") format("woff2"), url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_W_Bd.woff") format("woff"), url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_Bd.ttf") format("truetype"),
        url("https://assets.gulfstream.aero/fonts/horizon/bold/GulfstreamHorizon_Bd.otf") format("opentype");
}

@font-face {
    font-family: "Gulfstream Horizon Display";
    font-weight: 100;
    src: url("https://assets.gulfstream.aero/fonts/horizondisplay/light/GulfstreamHorizonDisplay_W_Lt.eot");
    src: url("https://assets.gulfstream.aero/fonts/horizondisplay/light/GulfstreamHorizonDisplay_W_Lt.eot?#iefix") format("embedded-opentype"), url("https://assets.gulfstream.aero/fonts/horizondisplay/light/GulfstreamHorizonDisplay_W_Lt.woff2") format("woff2"), url("https://assets.gulfstream.aero/fonts/horizondisplay/light/GulfstreamHorizonDisplay_W_Lt.woff") format("woff"), url("https://assets.gulfstream.aero/fonts/horizondisplay/light/GulfstreamHorizonDisplay_Lt.ttf") format("truetype");
}

@font-face {
    font-family: "Gulfstream Horizon Display";
    font-weight: 400;
    src: url("https://assets.gulfstream.aero/fonts/horizondisplay/regular/GulfstreamHorizonDisplay_W_Rg.eot");
    src: url("https://assets.gulfstream.aero/fonts/horizondisplay/regular/GulfstreamHorizonDisplay_W_Rg.eot?#iefix") format("embedded-opentype"), url("https://assets.gulfstream.aero/fonts/horizondisplay/regular/GulfstreamHorizonDisplay_W_Rg.woff2") format("woff2"), url("https://assets.gulfstream.aero/fonts/horizondisplay/regular/GulfstreamHorizonDisplay_W_Rg.woff") format("woff"),
        url("https://assets.gulfstream.aero/fonts/horizondisplay/regular/GulfstreamHorizonDisplay_Rg.ttf") format("truetype");
}
