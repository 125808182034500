@import "variables.less";
// override variables found in node_modules/uikit/src/less/components/
// below to create a Gulfstream theme
// Gulfstream colors, fonts, margins, etc,
// are defined in theme/uikit-gulfstream/variables.less

/* ******************************
        COMPONENT OVERRIDES
******************************** */

// off canvas
@offcanvas-bar-background: @gac-white;
@offcanvas-bar-color-mode: dark;
// cards
@card-title-font-size: @default-font-size;
// nav and navbar
@navbar-background: @gac-white;
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-nav > li > a {
	font-family: @font-family;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-cn !important;
	}
}

.uk-navbar-left {
	& .uk-navbar-toggle,
	.uk-search-toggle {
		padding: 0 15px 0 @global-margin;
		.responsive(@small-min-width-px, {
            padding: 0 15px 0 @global-large-margin;
        }
        );
	}
}

.uk-navbar-right {
	& .uk-navbar-toggle,
	.uk-search-toggle {
		padding: 0 @global-margin 0 15px;
		.responsive(@small-min-width-px, {
            padding: 0 @global-large-margin 0 15px;
        }
        );
	}
}

.uk-navbar-toggle {
	cursor: pointer;
}

.uk-navbar-nav > li > a {
	text-transform: none;
	// &:lang(ru) {
	//     text-transform: none;
	// }
}
.uk-dropdown.uk-open {
	display: inherit !important;
}
button {
	font-family: inherit;
}

// text-top correction
.uk-text-top {
	vertical-align: text-top !important;
}

.uk-text-meta {
	font-size: 0.85rem;
	color: @gac-grey8;
	& a {
		color: @gac-grey8;
		text-decoration: none;
		border-bottom: 1px solid @gac-grey8;
		padding-bottom: 1px;
	}
}

// heading styles (sizes are on variables.less)
.hook-heading-small() {
	font-family: @font-family-heading;
	text-transform: uppercase;
	font-weight: 100;
	color: @gac-black;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-heading-cn !important;
	}
}

.hook-heading-medium() {
	font-family: @font-family-heading;
	text-transform: uppercase;
	font-weight: 100;
	color: @gac-black;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-heading-cn !important;
	}
}

.hook-heading-large() {
	font-family: @font-family-heading;
	text-transform: uppercase;
	font-weight: 100;
	color: @gac-black;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-heading-cn !important;
	}
}

.hook-heading-xlarge() {
	font-family: @font-family-heading;
	text-transform: uppercase;
	font-weight: 100;
	color: @gac-black;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-heading-cn !important;
	}
}

.hook-heading-2xlarge() {
	font-family: @font-family-heading;
	text-transform: uppercase;
	font-weight: 100;
	color: @gac-black;
	&:lang(zh-hans),
	:lang(zh-hant) {
		font-family: @font-family-heading-cn !important;
	}
}

// code
:not(pre) > code {
	color: @gac-utility-green;
	font-size: 0.9em !important;
}

// tooltips
.uk-tooltip.uk-active {
	line-height: 140% !important;
}

/* SWITCH styles */

/* 
Usage:

<div class="uk-flex uk-flex-start" uk-margin">
	<div class="uk-width-auto">
		<label class="uk-switch" for="group-${index}">
			<input type="checkbox" id="group-${index}">
			<div class="uk-switch-slider"></div>
		</label>
	</div>
	<div class="uk-width-expand uk-margin-small-left">
		<label>My input label</label>
	</div>
</div>

*/
.uk-switch {
	position: relative;
	display: inline-block;
	height: 22px;
	width: 44px;
	&.small {
		height: 11px;
		width: 22px;
	}
}

/* Hide default HTML checkbox */
.uk-switch input {
	display: none;
}
/* Slider */
.uk-switch-slider {
	background-color: rgba(@gac-blue, 0.22);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border-radius: 500px;
	bottom: 0;
	cursor: pointer;
	transition-property: background-color;
	transition-duration: 0.2s;
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}
/* Switch pointer */
.uk-switch-slider:before {
	content: "";
	background-color: @gac-white;
	position: absolute;
	width: 20px;
	height: 20px;
	left: 1px;
	bottom: 1px;
	border-radius: 50%;
	transition-property: transform, box-shadow;
	transition-duration: 0.2s;
}
.uk-switch.small {
	& .uk-switch-slider:before {
		width: 10px;
		height: 10px;
	}
}
/* Slider active color */
input:checked + .uk-switch-slider {
	background-color: @gac-blue !important;
}
/* Pointer active animation */
input:checked + .uk-switch-slider:before {
	transform: translateX(21px);
}
.uk-switch.small {
	& input:checked + .uk-switch-slider:before {
		transform: translateX(10px);
	}
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
	background-color: rgba(@gac-utility-red, 0.5);
}
input:checked + .uk-switch-slider.uk-switch-on-off {
	background-color: @gac-utility-green !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
	transform: scale(1.2);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}
.uk-switch-slider.uk-switch-small:before {
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}
input:checked + .uk-switch-slider.uk-switch-big:before {
	transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
	background-color: rgba(255, 255, 255, 0.22);
}

/* ***************************
            mixins
***************************** */

.responsive(@minWidth;
@rules) {
	@media only screen and (min-width: @minWidth) {
		@rules();
	}
}
